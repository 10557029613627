import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"

const Watch = () => {
  const data = useStaticQuery(graphql`
      query {
          images: allFile(
              filter:{relativePath: { glob: "thumbnails/*" }}
              sort:{
                  fields: [relativePath]
                  order: ASC
              }
          ) {
              edges {
                  node {
                      childImageSharp {
                          fluid(maxWidth: 325) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
          }
      }
  `)

  const images = () => {
    return data.images.edges.map((edge, index) => {
      return <Img style={{
        maxWidth: `325px`,
        marginBottom: `1em`,
      }} key={"video-thumbnails" + index}
                  fluid={edge.node.childImageSharp.fluid}/>
    })
  }
  return (
    <>
      <SEO title="Watch"/>
      {images()}
      <Link to="/">
        <button style={{ width: `160px` }} type="button">Back</button>
      </Link>
    </>
  )
}

export default Watch
